@media (max-width:736px)
  and (min-width:550px) {

    .logo {
      margin-left: 45px;
    }

}


/* Iphone X  */
/* Iphone 6/7/8  */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 2) { 

    .logo {
      width: 34px;
      height: 60px;
    }

    .logo-bar {
      padding-top: 15px;
      height: 80px;
    }

  }


/* Iphone X Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
  
    .is-hidden.right-logo {
      display: block !important;
    }

    .column.left-logo-column {
      display: none;
    }

  }


/* Iphone X Portrait */
/* Iphone 6/7/8 Portrait */
/* Iphone 6/7/8 Plus Portrait */
@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) { 
  
  .column.title-column {
    margin-top: -100px;
  }

}


/* Iphone 6/7/8 Landscape */
/* Iphone 6/7/8 Plus Landscape */
@media only screen 
and (min-device-width: 667px) 
and (max-device-width: 736px) 
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: landscape) { 

  .column.title-column {
    margin-top: -100px;
  }

}


/* low rest tablet portrait */
@media (max-height:720px)
  and (orientation: portrait) {
  
    .logo {
      width: 34px;
      height: 60px;
    }

}