
.subtext-tagline-holder {
  position: relative;
  height: 100%;
}

.subtext-tagline {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-image: url('../images/tagline-image.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  width: 35px;
  height: 100px;
}

.home-block {
  border-left: 4px solid var(--dark-scarlet);
  padding-left: 45px;
  min-height: 50vh;
  color: var(--dark-scarlet);
}

.home-block h1 {
  font-family: var(--sub-title-font);
  font-weight: var(--sub-title-font-weight);
  font-size: var(--title-font-size);
  line-height: var(--title-font-size);
  margin-top: 60px;
}

.home-block h2 {
  font-family: var(--title-font);
  font-size: 50px;
  margin-bottom: 60px;
}

.home-block .searhing-for-title {
  font-size: 24px;
  font-weight: 300;
}

.home-block .searching-for-input {
  width: 30vw;
  background: none;
  font-family: var(--title-font);
  font-size: 64px;
  text-transform: capitalize;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--light-gray);

  margin-bottom: 60px;
}

.home-block button.home-next {
  display: block;
  font-family: var(--sub-title-font);
  font-weight: var(--sub-title-font-weight);
  font-size: 30px;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 60px;
}

.home-block button.home-next:hover {
  color: var(--scarlet);
}

.picture-wrapper {
  position: relative;
  height: 44vw;
  max-height: 540px;
}

.picture-top,
.picture-bottom {
  position: absolute;
}

.picture-top {
  z-index: 3;
  right: 0px;
  bottom: 0px;
  width: 35vw;
  height: 44vw;
  max-width: 430px;
  max-height: 540px;
  background-image: url('../images/home-picture-top.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.picture-bottom {
  z-index: 2;
  right: 50px;
  bottom: 0px;
  width: 30vw;
  height: 38vw;
  max-width: 350px;
  max-height: 438px;
  background-image: url('../images/home-picture-bottom.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}


@media (max-width:768px) {
  .home-block .searching-for-input {
    width: 75vw;
  }

  .home-block {
    border-left: none;
  } 

  .columns {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
  
}

@media (max-width:900px) {

  .home-columns .is-hidden-900 {
    display: none;
  }

  .home-block h2 {
    font-size: 46px;
  }

}

@media (max-width:1024px) and (min-width:701px) {
  .home-block .searching-for-input {
    width: 40vw;
  }

  .picture-bottom {
    right: 20px;
  }
}


/* Smaller screens */
@media only screen 
  and (max-device-width: 812px) { 
    
    .home-block h1 {
      margin-top: 5vh;
    }

  }

/* Iphone X Portrait */
/* Iphone 6/7/8 Portrait */
/* Iphone 6/7/8 Plus Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    
    h2.logo {
      margin-left: 0px !important;
    }

    .home-block {
      border-left: none;
      padding-left: 0px;
    } 
  
    .home-block h1 {
      font-size: 60px;
      margin-top: 5vh;
    }

    .home-block h2 {
      font-size: 38px;
    }

    .home-block .searching-for-input {
      font-size: 40px;
    }

    .columns {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
    }
  }


/* Iphone X Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 
    /* body { background-color: blueviolet;} */

    .column.is-2.is-hidden-mobile {
      display: none;
    }

    .home-block {
      border-left: none;
    } 

    .columns.home-columns {
      margin-top: -80px;
    }
  }


/* low rest tablet portrait */
@media (max-height:720px)
  and (orientation: portrait) {

    .home-block h1 {
      font-size: 60px;
      margin-top: 2vh;
    }

    .home-block h2 {
      font-size: 34px;
    }

    .home-block .searhing-for-title {
      font-size: 20px;
    }

    .footer {
      margin-top: 0px;
    }

    .home-block button.home-next {
      margin-bottom: 40px;
    }

}















