/* Iphone X Landscape */
@media only screen 
  and (min-device-width: 768px)  
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

    section.primary-header-section {
      margin-top: -80px;
    }

  }

@media only screen   
  and (max-device-width: 767px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

    section.primary-header-section {
      margin-top: -120px;
    }

  }