:root {
  --title-font: 'EB Garamond', serif;
  --title-font-weight: 500;
  --title-font-size: 75px;

  --sub-title-font: 'Josefin Sans', sans-serif;
  --sub-title-font-weight: 700;

  --dark-dark-gray: #001D23;
  --light-gray: #9A9A9A;
  --scarlet: #B60064;
  --dark-scarlet: #230013;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: var(--dark-scarlet);
}

select {
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

a {
  color: var(--scarlet);
}

.body-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.body-wrapper.white-background .wrapper {
  background-image: none;
}

.wrapper {
  flex: 1;
  background-image: url('images/gyfteo-background.jpg');
  background-size: contain;
  margin: 15px;
}

h1.title {
  font-family: var(--title-font);
  font-weight: var(--title-font-weight);
  font-size: var(--title-font-size);
  text-align: center;
  color: var(--dark-scarlet);
}

h1.small-title {
  font-family: var(--title-font);
  font-weight: var(--title-font-weight);
  font-size: 45px;
  text-align: center;
  color: var(--dark-scarlet);
}

.logo {
  background-image: url('images/gyfteo-logo-v2.png');
  background-size: cover;
  width: 60px;
  height: 100px;
}

span.prevent-break {
  white-space: nowrap ;
}

.card-image img {
  object-fit: contain;
}

.logo-bar {
  padding-top: 30px;
}

.secondary-title-block {
  padding-top: 30px;
}

.scarlet-highlight-line {
  width: 12vw;
  border-bottom: 3px solid var(--scarlet);
  margin: auto;
}

.searching-for-block {
  margin-top: 8vw;
  text-align: center;
}

p.searching-for {
  font-weight: 300;
  font-size: 30px;
}

input.gyfteo-name {
  width: 30vw;
  background: none;
  text-align: center;
  margin-top: 15px;
  color: #222222;

  font-family: var(--title-font);
  font-weight: var(--title-font-weight);
  font-size: 5vw;
  text-transform: capitalize;

  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--light-gray);
}

section.section-questions {
  margin-top: 5vw;
}

section.section-results {
  margin-top: 2vw;
}

section.section-go-button {
  margin-top: 1vw;
}

section.section-questions {
  text-align: center;
}

section.section-questions span.question-text {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  font-size: 30px;
  margin-right: 15px;
  margin-bottom: 30px;
  display: block;
}

.go-button-column {

}

button.go-button {
  font-family: var(--sub-title-font);
  font-weight: var(--sub-title-font-weight);
  font-size: 2vw;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
}

section.section-questions select {
  /* margin: .4vw 10px; */
  margin-left: 5px;
  margin-right: 2px;
  padding: 7px;
  border-color: var(--light-gray);
  border-radius: 5px;
  cursor: pointer;
  line-height: 24px;

  font-weight: 300;

  width: 250px;
  background: transparent;
}

.body-wrapper .footer {
  padding: 1rem;
  padding-top: 0;
  background-color: #fff;
}

.card-equal-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-equal-height .card-footer {
 margin-top: auto;
}

.result-count {
  color: #9A9A9A;
  margin-bottom: 45px;
}

.result-count .back-link {
  color: #9A9A9A;
  cursor: pointer;
}

.result-count .back-link:hover {
  color: #222222;
}

.no-results-link {
  font-family: var(--sub-title-font);
  font-size: 14px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: #310325;
  cursor: pointer;
}

.no-results-link:hover {
  color: var(--scarlet);
}

.modal-content .box {
  padding: 3rem 1.25rem;
}

/* Iphone 6, 7, & related */
@media only screen and (max-width: 768px) {

  .modal-content {
    width: 80%;
  }

  h1.title {
    font-size: 30px;
  }

  h1.small-title {
    margin-top: 15px;
    font-size: 30px;
  }

  .scarlet-highlight-line {
    width: 25vw;
  }

  p.searching-for {
    font-size: 25px;
  }

  input.gyfteo-name {
    font-size: 8vw;
    width: 55vw;
    margin-top: 25px;
  }
  
  section.section-questions span.question-text {
    font-size: 20px;
    margin-right: 0px;
    margin-bottom: 30px;
  }

  section.section-questions select {
    padding: 5px;
    /* font-size: 13px; */
    width: 100px;
  }

  .result-count {
    font-size: 14px;
  }
}

/* Iphone X and related in landscape */
@media (max-width:900px) and (min-width:768px) and (orientation: landscape) {

  .primary-title-block,
  .section-questions {
    margin-top: 0vw !important;
  }

  h1.title {
    font-size: 30px;
  }
  
  section.section-questions span.question-text {
    font-size: 18px;
    margin-bottom: 15px;
  }

  section.section-questions select {
    width: 150px;
  }

  a.gyft-it { 
    font-size: 14px;
  }

}

/* Iphone X Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

    h1.small-title {
      font-size: 30px;
    }

    section.section-questions, 
    section.section-results {
      margin-top: 8vw;
    }

    .result-count { 
      font-size: 14px; 
    }
}

/* tablet landscape */
@media (max-width:1024px) and (min-width:900px) and (orientation: landscape) {

  h1.title {
    font-size: 60px;
  }

  section.section-questions span.question-text {
    font-size: 25px;
    margin-bottom: 30px;
  }

  section.section-questions select {
    width: 250px;
  }

}

/* low rest tablet landscape */
@media (max-width:720px) and (orientation: landscape) {

  h1.title {
    font-size: 35px;
  }

  section.section-questions {
    margin-top: 0px;
  }

  section.section-questions span.question-text {
    font-size: 20px;
    margin-bottom: 30px;
  }

  section.section-questions select {
    width: 200px;
  }

  .footer {
    margin-top: 3vw;
    font-size: 12px;
  }

}