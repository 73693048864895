.about-block {
  padding-right: 45px;
}

.about-block h2 {
  line-height: 60px;
}

.about-block p {
  margin-bottom: 30px;
}