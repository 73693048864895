.load-more-results {
  width: 300px;
  padding: 15px;
  margin: 30px auto;
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--scarlet);
  color: var(--scarlet);
  cursor: pointer;
}

.load-more-results:hover {
  border: 1px solid var(--dark-scarlet);
  color: var(--dark-scarlet);
}