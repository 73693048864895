.footer-subtitle {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  font-family: var(--title-font);
}

.footer-subtitle span {
  display: block;
}

@media only screen 
  and (max-device-width: 812px) {
 
  .column .footer-subtitle.is-hidden {
    display: block !important;
  }

}